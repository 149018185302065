<template>
  <div style="margin-top: 50%;margin-left: 20%">
    <img src="https://czsc.oss-cn-beijing.aliyuncs.com/bj/signature_success.png">
  </div>
</template>

<script>
import {Encrypt} from "@/utils/secret";
import {ElMessage} from "element-plus";

export default {
  name: "",
  data() {
    return {}
  },
  created() {

  },
  methods: {}
}
</script>

<style scoped>

</style>